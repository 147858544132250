<template>
  <div id="app">
  <Header :title_en="title_en"/>
  <Content :title="title"/>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Content from './components/Content.vue'

export default {
  name: 'App',
  components: {
    Header,
    Content
  },
  data() {
    return {
      title:'声破天',
      title_en:'ShengPoTian'
    }
  }
}
</script>

<style>
body{
  margin: 0;
}
</style>
