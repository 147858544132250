<template>
<div class="header">
  <div class="header_row">
    <div class="header_img">
      <img src="../../src/assets/logo.png" alt="">
    </div>
    <div class="header_title">{{title_en}}</div>
  </div>
  
</div>
</template>
<script>
export default {
  name:"Header",
  props:["title_en"],
  data() {
    return {
      
    }
  }
}
</script>
<style scoped>
.header{
  margin: 0;
  padding:0;
  background: linear-gradient(50deg,#965ed6,#b0f);
  align-items: center;
  display: flex;
  position: relative;
  z-index: 0;
  height: 56px;
  box-shadow: 0 0 0 0 rgb(0 0 0 / 20%), 0 0 0 0 rgb(0 0 0 / 14%), 0 0 0 0 rgb(0 0 0 / 12%);
}
.header_row{
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
}
.header_img{
  text-align: center;
  height: 48px;
  line-height: 66px;
  width:48px;
  margin-left:2px;
}
.header_img img{
  width:32px;
  height: 32px;
}
.header_title{
  color:#fff;
  font-size: 18px;
  height: 48px;
  line-height: 48px;
  padding-left: 4px;
}
</style>