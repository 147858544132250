import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import axios from 'axios'
import qs from 'qs'


Vue.config.productionTip = false

Vue.use(ElementUI);

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
axios.defaults.withCredentials = true
Vue.prototype.$http = axios
Vue.prototype.$qs = qs


new Vue({
  render: h => h(App),
}).$mount('#app')
