<template>
  <div class="container">
    <div class="row" >     
        <div class="title" >您还不是 {{title}} 会员？</div>
        <div class="subtitle">
          请在淘宝【SPT小铺】购买充值码，按下面步骤操作充值会员！
        </div>
      <div class="subtitle">
          <b><font color="red">邮箱必须是自己的，升级会异地登录，可能需要收邮件重置密码！</font></b>
        </div>
        <div class="subtitle">

        </div>
    </div>
    <el-divider></el-divider>
  <div class="form_title_box">
    <div class="form_title">账户升级</div>
    <div class="form_title_icon" @click="resetForm('ruleForm')"><i class="el-icon-refresh-right
"></i></div>
  </div>

  <el-form  :model="ruleForm" :rules="rules" ref="ruleForm" label-width="50px" class="demo-ruleForm">
    
    <el-form-item label-width="50px" class="form-item" prop="user_email">
      <span class="label-icon" slot="label">
        <i class="el-icon-message form-icon" :style="emailicon"></i>
      </span>
      <div class="form-input">
        <label @click="labelToTop('email')" class="form-label" :class="emailactive" :style="emaillabel">* {{title}} 账户邮箱 <el-tooltip class="item" effect="light" content="如果邮箱未注册，系统会自动给您注册，并充值会员！" placement="top">
            <i class="el-icon-question" />
        </el-tooltip></label>

        <el-input ref="email" @blur="labelToBot('email')" @focus="labelToTop('email')" v-model="ruleForm.user_email" ></el-input>


      </div>


    </el-form-item>


    <el-form-item label-width="50px" class="form-item" prop="user_passwd">
      <span class="label-icon" slot="label">
        <i class="el-icon-lock form-icon" :style="passwordicon"></i>
      </span>
      <div class="form-input">
        <label @click="labelToTop('password')" class="form-label" :class="passwordactive" :style="passwordlabel">* {{title}} 账户密码</label>
        <el-input ref="password" :show-password="true" @blur="labelToBot('password')" @focus="labelToTop('password')" v-model="ruleForm.user_passwd"></el-input>
      </div>    
    </el-form-item>

    <el-form-item label-width="50px" class="form-item" prop="redom">
      <span class="label-icon" slot="label">
        <i class="el-icon-bank-card form-icon" :style="paycodeicon"></i>
      </span>
      <div class="form-input">
        <label @click="labelToTop('paycode')" class="form-label" :class="paycodeactive" :style="paycodelabel">* 充值代码</label>
        <el-input ref="paycode" minlength="10" @blur="labelToBot('paycode')" @focus="labelToTop('paycode')" v-model="ruleForm.redom" clearable>
        <el-button v-if="!ruleForm.redom" slot="append" style="background:#1976d2;color:#fff;"><a style="color:#fff;text-decoration:none;padding:10px 0" :href="'https://buy.shengpotian.shop'">立即购买</a></el-button>
        </el-input>
      </div>    
    </el-form-item>

    <el-form-item label-width="50px" class="form-item" prop="contact">
      <span class="label-icon" slot="label">
        <i class="el-icon-postcard form-icon" :style="contacticon"></i>
      </span>
      <div class="form-input">
        <label @click="labelToTop('contact')" class="form-label" :class="contactactive" :style="contactlabel">联系邮箱（选填）</label>
        <el-input ref="contact" @blur="labelToBot('contact')" @focus="labelToTop('contact')" v-model="ruleForm.contact"></el-input>
      </div>    
    </el-form-item>

   
   
    
      <el-button style="width:100%;padding:15px 20px" :type="submittype" @click="submitForm('ruleForm')" :disabled="submitstatus">立即升级</el-button>
    
  </el-form>

<el-dialog width="80%" top="35vh" :close-on-click-modal="false" :show-close="false" :close-on-press-escape="false" :modal="true" :visible.sync="dialogVisible">
  <i class="el-icon-loading dialog_icon"></i>
  <div class="dialog_text1">正在升级中(3~5分钟)，请勿关闭页面！</div>
  <div class="dialog_text2">成功后需要重登账户以使会员生效！</div>
  <div class="dialog_text3">如过长时间无结果，请及时<a class="dialog_link" href="javascript:;">联系客服</a>查看！</div>
</el-dialog>
  </div>
</template>

<script>
export default {
  name:"Content",
  props:{
    title:String
  },
  data() {
     let checkEmail = (rule, value, callback) => {   
        if(!value){
          this.emaillabel = "color:#ff5252"
          this.emailicon = 'color:#ff5252'
          this.$refs.email.$refs.input.style.border = '1px solid red'
          return callback(new Error('请输入邮箱'))
        }else{
          let reg = /^[A-Za-z0-9\u4e00-\u9fa5\.+-_*]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
          if(!reg.test(value)){
            this.emaillabel = "color:#ff5252"
            this.emailicon = 'color:#ff5252'
            this.$refs.email.$refs.input.style.border = '1px solid red'
            return callback(new Error('无效的 邮箱格式'))
          }
          return callback()
        }
      }
      let checkPass = (rule, value, callback) => {
        if(!value){
          this.passwordlabel = "color:#ff5252"
          this.passwordicon = 'color:#ff5252'
          this.$refs.password.$refs.input.style.border = '1px solid red'
          return callback(new Error('请输入密码'))
        }else{
          if(value.length < 8){
            this.passwordlabel = "color:#ff5252"
            this.passwordicon = 'color:#ff5252'
            this.$refs.password.$refs.input.style.border = '1px solid red'
            return callback(new Error('无效的密码！至少需要8位！'))
          }else{
            return callback()
          }
        }
      }

      let checkCode = (rule, value, callback) => {
        if(!value){
          this.paycodelabel = "color:#ff5252"
          this.paycodeicon = 'color:#ff5252'
          this.$refs.paycode.$refs.input.style.border = '1px solid red'
          return callback(new Error('请输入充值代码'))
        }else{
          let reg = /^[A-Za-z]{10,}$/
          if(value.length < 10 || !reg.test(value)){
            this.paycodelabel = "color:#ff5252"
            this.paycodeicon = 'color:#ff5252'
            this.$refs.paycode.$refs.input.style.border = '1px solid red'
            return callback(new Error('无效的充值代码'))
          }else{
            return callback()
          }
        }
      }
      let checkContact = (rule, value, callback) => {
        if(value){
          let reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
          if(!reg.test(value)){
            this.contactlabel = "color:#ff5252"
            this.contacticon = 'color:#ff5252'
            this.$refs.contact.$refs.input.style.border = '1px solid red'
            return callback(new Error('无效的 邮箱格式'))
          }
        }
        return callback()
      }
    return {
          emailactive:{"label--active":false},
          emaillabel:'',
          emailicon:'',
          passwordactive:{"label--active":false},
          passwordlabel:'',
          passwordicon:'',
          paycodeactive:{"label--active":false},
          paycodelabel:'',
          paycodeicon:'',
          contactactive:{"label--active":false},
          contactlabel:'',
          contacticon:'',
          ruleForm: {
            user_email: '',
            user_passwd: '',
            redom: '',
            contact: ''
          },
          submittype:"info",
          submitstatus:true,
          dialogVisible:false,
          //淘宝APP详情页面  右上角分享--复制链接
          tbURL:"http://item.taobao.com/item.htm?id=767966999242 ", //淘宝详情页地址
          rules: {
            user_email:[
              { validator:checkEmail, trigger:'blur' }
            ],          
            user_passwd: [
              { validator:checkPass, trigger: 'blur' }
            ],
            redom: [
              { validator:checkCode, trigger: 'blur' }
            ],
            contact: [
              { validator:checkContact, trigger: 'blur' }
            ]
          }
    }
  },
  watch:{
    ruleForm:{
      handler(nv,ov){
        if(nv.user_email && nv.user_passwd && nv.redom){
          this.submittype = 'primary'
          this.submitstatus = false
        }else{
          this.submittype = 'info'
          this.submitstatus = true
        }
      },
      deep:true
    }
  },
  methods: {
    submitForm(formName) {
      this.topFunction()
      this.$refs[formName].validate((valid) => { 
        if (valid) {

          this.getID()    
        } else {  
          console.log('error submit!!');
          return false;
        }
      });
    },
    topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    },
    getID(){
          const url = '/api/getID.json'
          const formModel = this.ruleForm
          this.dialogVisible = true
          this.$http.post(url,formModel).then((res) => {
          //this.$http.get(url).then((res) => {
            if(res.data.id){
              this.getRes(res.data.id)
            }else{
              this.$notify({
                title: '消息',
                showClose: true,
                message:'矮油,系统蹦了,请联系客服处理！',
                type:"error",
                duration:0
              })
            }       
          }).catch((err)=>{
            setTimeout(()=>{
              this.dialogVisible = false
            },1000)
            console.log(err)
            this.$notify({
              showClose: true,
              title: '消息',
              message:'请求失败，请检查网络状况',
              type:'error',
              duration:0
            })
          })
    },
    getRes(id){
          const url2 = '/api/getResult.json'
           this.$http.post(url2,{ id }).then((r) => {
          //this.$http.post(url2).then((r) => {
            if(r.data.code && r.data.code == 2){
              this.dialogVisible = true
              //轮询处理
              setTimeout(()=>{
                this.getRes(id)
              },5000)

            }else if(r.data.code && r.data.code !== 0){
              setTimeout(()=>{
                this.dialogVisible = false
              },1000)
                this.$notify({
                  showClose: true,
                  title:'通知：',
                  message:r.data.msg,
                  type:"error",
                  duration:0
                })
            }else{
              setTimeout(()=>{
                this.dialogVisible = false
              },1000)


              this.$notify({
                showClose: true,
                title: '升级成功',
                message:r.data.msg,
                type:"success",
                duration:0
              })
            }
            
          }).catch((err)=>{
            setTimeout(()=>{
              this.dialogVisible = false
            },1000)
            console.log(err)

            this.$notify({
              showClose: true,
              title: '消息',
              message:'请求失败，请检查网络状况',
              type:'error',
              duration:0
            })
          })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    labelToTop(tab){
      if(tab == 'paycode'){
        this.$refs.paycode.$refs.input.style.fontSize = '14px'
      }
      let activestr = tab+'active'
      let labelstr = tab+'label'
      let iconstr = tab+'icon'
      this[activestr] = {"label--active":true}
      this[labelstr] = "color:#1976d2"
      this[iconstr] = 'color:#1976d2'
      this.$refs[tab].focus()
      this.$refs[tab].$refs.input.style.border = '1px solid #1976d2'
    },
    labelToBot(tab){
      let value = ''
      switch(tab){
        case 'email':
          value = this.ruleForm.user_email
          break
        case 'password':
          value = this.ruleForm.user_passwd
          break
        case 'paycode':
          value = this.ruleForm.redom
          break
        default:
          value = this.ruleForm.contact
      }
      
      if(!value){
        let activestr = tab+'active'  
        this[activestr] = {"label--active":false}
      }
      let labelstr = tab+'label'
      this[labelstr] = "color:#000"
      let iconstr = tab+'icon'
      this[iconstr] = 'color:#000'
      this.$refs[tab].$refs.input.style.border = '1px solid rgba(0, 0, 0, .7)'        
    }
  }
};
</script>

<style scoped>
.container{
    padding: 12px;
    margin-right: auto;
    margin-left: auto;
    max-width:800px;
}
.container >>> .el-dialog{
  max-width: 500px;
}
.container >>> .el-dialog__body{
  padding:0;
  text-align: center;
  padding-bottom: 30px;
}
.dialog_icon{
  font-size: 50px;
  padding-bottom: 8px;
}
.dialog_text1{
  font-size: 15px;
  font-weight: 400;
  padding-bottom: 8px;
}
.dialog_text2{
  color:red;
  font-weight: bold;
  font-size: 16px;
  padding-bottom: 8px;
}
.dialog_text3{
  color:rgba(0, 0, 0, .5)
}
.row {
    text-align: center;
    padding-top: 0!important;
    padding-bottom: 0!important;
}

.title{
    font-size: 19px!important;
    font-weight: 500;
    letter-spacing: .0125em!important;
}
.subtitle{
    font-size: 16px!important;
    letter-spacing: .009375em!important;
    line-height: 1.75rem;
    font-weight: 400;
    margin-top: 4px;
}
.el-divider--horizontal{
  margin:8px 0 !important;
}
.form_title_box{
  display: flex;
  height: 40px;
  line-height: 40px;
  margin-top: 14px;
  justify-content: flex-start;
}
.form_title{
  font-weight: bold;
  font-size: 24px;
}
.form_title_icon{
  margin-left: auto;
}
.form_title_icon{
  font-size: 22px;
  color: #fb8c00 ;
  font-weight: bold;
}
.form-icon{
  font-size: 26px;
}
.form-input >>> .el-input__inner{
  border-top:none !important;
  border-left:none !important;
  border-right:none !important;
  border-bottom: 1px solid rgba(0, 0, 0, .7);
  border-radius: 0 !important;
  padding:0 5px;
  font-size: 18px;
}
.label--active{
  transform: translateY(-25px) scale(.75);
  margin-left: -10px;
  color:#1976d2
}
.form-input{
  position: relative;
}
.form-label{
  position: absolute;
  top: 0;
  z-index: 999;
  font-size: 18px;
}
.form-item{
  margin-top:30px;
}
.demo-ruleForm{
  margin-top:20px;
}
.demo-ruleForm >>> .el-form-item__label{
  line-height: 50px !important;
}
.demo-ruleForm >>> .el-form-item__error{
  padding-top: 0 !important;
  top:90%;
}

</style>